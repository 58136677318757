<template>
  <div class="mt-2">
    <button class="mb-2 btn btn-default btn-sm" @click="this.$router.go(-1)">
      Back
    </button>
    <div class="card shadow w-100 p-4">
      <div class="row mb-1 text-center">
        <div class="col-md-12">
          <h3 v-if="this.$route.params.status === 'debit'">
            Add bank Deposit (Bank Cashin)
          </h3>
          <h3 v-else-if="this.$route.params.status === 'credit'">
            Add bank Transfer (Bank Cashout)
          </h3>
          <h3 v-else>Add bank cashflow</h3>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6">
          <label class="form-control-label">Choose form</label>
          <span class="ml-1 text-danger">*</span>
          <select
            class="form-control form-control-alternative"
            v-model="selectedServiceCatalogs"
          >
            <option
              v-for="(cat, index) in serviceCatalogs"
              :key="index"
              :value="cat.fields"
              >{{ cat.name }}</option
            >
          </select>
        </div>
      </div>
      <hr class="my-1 mb-3" v-if="inputFields.length > 0" />
      <div class="row mb-3" v-if="inputFields.length > 0">
        <div
          class="col-md-6"
          v-for="(field, index) in inputFields"
          :key="index"
        >
          <label class="form-control-label">{{ field.named }}</label>
          <span class="ml-1 text-danger">*</span>
          <input
            class="form-control form-control-alternative"
            :placeholder="'please add ' + field.named"
            v-model="inputFields[index][field.named]"
          />
        </div>
      </div>
      <div class="row mt-5" v-if="booleanFields.length > 0">
        <div class="col-md-12">
          <h4 class="my-0">
            The following are considered as Yes (True)
          </h4>
          <hr class="my-3" />
        </div>
      </div>
      <div class="row" v-if="booleanFields.length > 0">
        <div
          class="col-md-4 my-1"
          v-for="(booleanField, index) in booleanFields"
          :key="index"
        >
          <label class="form-control-label">{{ booleanField.named }}</label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mt-2">
          <button class="btn btn-secondary px-6" @click="this.$router.go(-1)">
            Cancel
          </button>

          <!-- <button-loader :title="btnTitle" :classes="classes" :show_loader="show_loader" @click="saveBankCashFlow()"/> -->
          <button
            :disabled="show_loader"
            class="btn btn-md btn-default my-2 px-4"
            :style="!show_loader ? '' : styleObject"
            @click="saveBankCashFlow()"
          >
            Submit
            <i v-if="show_loader" class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../../firebase";
import { collection, getDocs, addDoc } from "firebase/firestore";
import { alert } from "../../utils/alertUtils";
export default {
  data() {
    return {
      serviceCatalogs: [],
      selectedServiceCatalogs: [],
      inputFields: [],
      booleanFields: [],
      data: {},
      styleObject: {
        cursor: "not-allowed",
      },
      selected: 0,
      btnTitle: "Add Service ",
      show_loader: false,
      classes: "btn btn-md btn-default my-2 px-4",
    };
  },
  watch: {
    // Method to add all fields with input in its array
    // and add boolean field in its array
    selectedServiceCatalogs: function(val) {
      this.inputFields = [];
      this.booleanFields = [];
      val.forEach((element) => {
        if (element.type == "input") {
          element[element.named] = ""; // clear so that in v-model it will accept data.
          this.inputFields.push(element);
        } else if (element.type == "boolean") {
          this.booleanFields.push(element);
        }
      });
    },
  },
  components: {},
  methods: {
    // Method to set service data

    saveBankCashFlow: function() {
      this.show_loader = true;

      // create an object to be saved , by taking
      // added field name and value
      // finally add all boolean field in the data object
      // as true.
      let data = {};
      this.inputFields.forEach((e) => {
        data[e.named] = e[e.named];
      });
      this.booleanFields.forEach((e) => {
        data[e.named] = true;
      });
      this.data = data;

      const subCollectionRef =
        this.$route.params.status === "debit"
          ? collection(db, "banks", this.$route.params.uuid, "debits")
          : collection(db, "banks", this.$route.params.uuid, "credits");

      addDoc(subCollectionRef, {
        date_inserted: new Date(),
        userId: this.$store.getters.getUserId,
        ...this.data,
      })
        .then(() => {
          this.show_loader = false;
          alert.success_center("Action completed successfully");
          this.$router.push({
            name: "Bank Management",
            params: { uuid: this.$route.params.uuid },
          });
        })

        .catch((e) => {
          this.show_loader = false;
          alert.error("an error occured please try again.");
          console.log(e);
        });
    },

    fetch_service_catalog: function() {
      // fetch collections
      getDocs(collection(db, "serviceCatalog"))
        .then((snapShots) => {
          snapShots.forEach((document) => {
            let sc = document.data();
            sc.uuid = document.id;
            this.serviceCatalogs.push(sc);
          });
        })
        .then(() => {
          this.data_loaded = true;
        })
        .catch((e) => {
          console.log(`an erro occured ${e}`);
          this.data_loaded = false;
        });
    },
    clearForm: function() {
      this.data = {};
    },
  },
  created() {
    this.fetch_service_catalog();
  },
};
</script>

<style lang="scss" scoped></style>
